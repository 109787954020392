/* ==========================================================================
   CSS Custom Properties for Light Mode (Default)
   ========================================================================== */
   :root {
    /* Basic Colors */
    --color-bg: #ffffff;
    --color-text: #333333;
    --color-primary: #E6007A; /* Polkadot pink */
    --color-secondary: #007acc;
    --color-border: #ccc;
    --color-shadow: rgba(0, 0, 0, 0.3);
  
    /* Card & Section Backgrounds */
    --card-bg: #ffffff;
    --polkadot-card-bg: #ffffff; /* used in gradient */
    --voting-table-bg: #ffffff;
    --content-section-bg: #f5f5f5;
    --tutorial-bg: #f5f5f5;
    --tutorial-text: #1e1e1e;
  
    /* Specific UI Elements */
    --input-border: #ccc;
    --input-focus-border: #000000;
    --query-button-bg: #E6007A;
    --query-button-hover-bg: #c2185b;
    --table-header-bg: #f2f2f2;
    --table-header-text: #333;
    --table-row-even-bg: #fafafa;
    --table-row-odd-bg: #fff;
    --table-row-hover-bg: #f1f1f1;
    --tab-button-color: #333;
    --tab-button-active-border: #e6007a;
    --tab-button-hover-bg: #f0f0f0;
    --pagination-button-bg: #e6007a;
    --pagination-button-disabled-bg: #ccc;
    --title-bg: #f0f0f0;
    --title-text: #333;
    --tooltip-bg: rgba(0, 0, 0, 0.75);
    --nft-card-bg: #ffffff;
    --nft-button-bg: #E6007A;
    --nft-button-hover-bg: #FF3391;
    --polkadot-card-alt-bg: #f4f4f4;
    --polkadot-card-alt-title: #e6007a;
  
    /* For metric boxes and similar components */
    --metric-box-bg: #fff;
    --metric-box-border: #ccc;
    --metric-box-title-color: #e6007a;
    --metric-item-bg: rgba(255, 255, 255, 0.15);
    --metric-item-text: #000000;
  
    /* Ant Design overrides */
    /* --ant-menu-hover-bg: #91d5ff;
    --ant-menu-hover-text: #fff;
    --ant-sider-trigger-bg: #fff;
    --ant-sider-trigger-text: #E6007A; */
  
    /* Dashboard and Layout */
    --dashboard-bg: #f0f2f5;
  }
  
  /* ==========================================================================
     Dark Mode Overrides (Apply by adding the class "dark" to the <body>)
     ========================================================================== */
  body.dark {
    /* Basic Colors */
    --color-bg: #1f1f1f;
    --color-text: #ffffff;
    --color-primary: #E6007A; /* remains same */
    --color-secondary: #007acc; /* remains same */
    --color-border: #555;
    --color-shadow: rgba(0, 0, 0, 0.5);
  
    /* Card & Section Backgrounds */
    --card-bg: #333333;
    --polkadot-card-bg: #1f1f1f;
    --voting-table-bg: #333333;
    --content-section-bg: #333333;
    --tutorial-bg: #282c34;
    --tutorial-text: #ffffff;
  
    /* Specific UI Elements */
    --input-border: #444;
    --input-focus-border: #ffffff;
    --query-button-bg: #E6007A; /* remains same */
    --query-button-hover-bg: #c2185b;
    --table-header-bg: #2a2a2a;
    --table-header-text: #ffffff;
    --table-row-even-bg: #3a3a3a;
    --table-row-odd-bg: #333333;
    --table-row-hover-bg: #444444;
    --tab-button-color: #ffffff;
    --tab-button-active-border: #e6007a;
    --tab-button-hover-bg: #2a2a2a;
    --pagination-button-bg: #e6007a;
    --pagination-button-disabled-bg: #444;
    --title-bg: #2a2a2a;
    --title-text: #ffffff;
    --tooltip-bg: rgba(0, 0, 0, 0.7);
    --nft-card-bg: #333333;
    --nft-button-bg: #E6007A;
    --nft-button-hover-bg: #FF3391;
    --polkadot-card-alt-bg: #2a2a2a;
    --polkadot-card-alt-title: #e6007a;
  
    /* For metric boxes and similar components */
    --metric-box-bg: #333333;
    --metric-box-border: #444;
    --metric-box-title-color: #e6007a;
    --metric-item-bg: rgba(0, 0, 0, 0.15);
    --metric-item-text: #ffffff;
  
    /* Ant Design overrides */
    
    /* --ant-menu-hover-bg: #001529;
    --ant-menu-hover-text: #fff;
    --ant-sider-trigger-bg: #001529;
    --ant-sider-trigger-text: #E6007A; */
  
    /* Dashboard and Layout */
    --dashboard-bg: #1f1f1f;
  }
  
  /* ==========================================================================
     Original CSS Rules with Variable Replacements
     ========================================================================== */
  
  /* Headers */
  h1, h2 {
    margin: 0;
    line-height: 1.25;
    font-family: 'Unbounded', cursive;
  }
  h1 {
    font-weight: 1000;
  }
  h2 {
    font-weight: 700;
  }
  h3 {
    margin: 0;
    line-height: 1.25;
    font-weight: 400;
    font-family: 'Unbounded', cursive;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-bg);
    color: var(--color-text);
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  /* Card container styling */
  .polkadot-card {
    background: linear-gradient(135deg, var(--polkadot-card-bg), var(--polkadot-card-bg));
    padding: 30px;
    border-radius: 16px;
    color: var(--color-primary);
    box-shadow: 0px 6px 15px var(--color-shadow);
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  /* Grid styling for metrics */
  .metrics {
    display: flex;
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;
  }
  
  .metricsLive {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  /* Individual metric items */
  .metric-item {
    flex: 1;
    position: relative;
    background: var(--metric-item-bg);
    padding: 18px;
    margin-top: 15px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #ded9dc;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  }
  .metric-item span {
    display: block;
    color: var(--metric-item-text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .metric-item::before {
    content: attr(data-title);
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    background-color: var(--color-bg);
    padding: 2px 12px;
    border-radius: 5px;
  }
  
  /* Address input and query button container */
  .input-group {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .input-group input {
    flex: 1;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--input-border);
    font-size: 1rem;
  }
  .input-group input:focus {
    outline: none;
    border-color: var(--input-focus-border);
    box-shadow: 0 0 5px rgba(230, 0, 122, 0.5);
  }
  
  /* Query button styling */
  .query-button {
    padding: 15px 25px;
    background-color: var(--query-button-bg);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .query-button:hover {
    background-color: var(--query-button-hover-bg);
  }
  
  /* Error message styling */
  .error-message {
    color: var(--color-primary);
    margin-top: 15px;
  }
  
  /* Voting Table Account Explorer */
  .voting-table {
    margin-top: 20px;
    overflow-x: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .voting-table table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--voting-table-bg);
    border-radius: 8px;
  }
  .voting-table th,
  .voting-table td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #e5e5e5;
  }
  .voting-table th {
    background-color: var(--color-primary);
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
  }
  .voting-table tr:nth-child(even) {
    background-color: #fafafa;
  }
  .voting-table tr:hover {
    background-color: #ffe0f0;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    .voting-table th,
    .voting-table td {
      padding: 10px;
    }
  }
  
  /* Tab navigation */
  .tab-navigation {
    margin-top: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
  }
  .tab-button {
    padding: 10px 20px;
    margin-right: 5px;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    font-size: 16px;
    color: var(--tab-button-color);
  }
  .tab-button.active {
    border-bottom-color: var(--tab-button-active-border);
    font-weight: bold;
  }
  .tab-button:hover {
    background-color: var(--tab-button-hover-bg);
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: var(--pagination-button-bg);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .pagination button:disabled {
    background-color: var(--pagination-button-disabled-bg);
  }
  
  /* Title description container */
  .title-description-container {
    position: relative;
    cursor: pointer;
    padding: 10px;
    background: var(--title-bg);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  .title {
    margin: 0;
    color: var(--title-text);
    font-size: 1.5em;
  }
  .description {
    visibility: visible;
    opacity: 1;
    color: #666;
    margin-top: 10px;
  }
  
  /* NFT components */
  .nft-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
    background-color: var(--nft-card-bg);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  .nft-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: var(--color-text);
  }
  .nft-button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .nft-button {
    padding: 12px 24px;
    background-color: var(--nft-button-bg);
    color: white;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  .nft-button:hover {
    background-color: var(--nft-button-hover-bg);
  }
  .nft-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .nft-gallery {
    margin-top: 40px;
  }
  .nft-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  .nft-card {
    background-color: var(--nft-card-bg);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: calc(33.333% - 20px);
    box-sizing: border-box;
    text-align: center;
  }
  .nft-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .nft-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Alternate Polkadot Card */
  .polkadot-card {
    width: 100%;
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: var(--polkadot-card-alt-bg);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(90, 88, 88, 0.15);
  }
  .polkadot-card h1 {
    text-align: center;
    color: var(--polkadot-card-alt-title);
    margin-bottom: 0;
    font-size: 2rem;
  }
  
  /* Metrics container */
  .metrics-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }
  .metric-box {
    position: relative;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(226, 223, 223, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    background-color: var(--metric-box-bg);
    border: 1px solid var(--metric-box-border);
    height: 70px;
  }
  .metric-box::before {
    content: attr(data-title);
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--metric-box-bg);
    padding: 0 10px;
    font-size: 0.75rem;
    color: var(--metric-box-title-color);
    border-radius: 10px;
    box-shadow: none;
    z-index: 0;
    white-space: nowrap;
  }
  .metric-box:hover {
    transform: scale(1.05);
  }
  .metric-value {
    font-size: 1.2rem;
    color: var(--color-text);
    margin-top: 2px;
    transition: transform 2s ease-out;
    display: inline-block;
    padding: 2px 2px;
    border-radius: 2px;
  }
  .metric-value.updated {
    transform: scale(1.1);
  }
  
  /* Metrics sections */
  .metrics-section {
    margin-bottom: 20px;
  }
  .metrics-subsection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background: var(--card-bg);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .metrics-subsection h2 {
    text-align: center;
    color: var(--color-primary);
    margin-bottom: 10px;
  }
  .metrics-section h2 {
    text-align: center;
    color: var(--color-primary);
    margin-bottom: 10px;
  }
  
  /* Circular chart */
  .circular-chart {
    width: 60px;
    height: 60px;
  }
  .circle-bg {
    fill: none;
    stroke: #eee;
  }
  .circle {
    fill: none;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  /* Staking toggle button */
  .staking-toggle-button {
    background: none;
    border: none;
    color: var(--color-primary);
    font-size: 1.05rem;
    cursor: pointer;
    position: absolute;
    top: 20%;
    right: 1%;
  }
  
  /* Title container */
  .title-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  .metric-box:hover .tooltip {
    display: block;
  }
  .tooltip {
    display: none;
    position: absolute;
    z-index: 1000;
    font-size: 12px;
    color: #fff;
    background-color: var(--tooltip-bg);
    padding: 10px;
    border-radius: 5px;
    max-width: 300px;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0,0,0,0.4);
    top: -40px;
    left: -4%;
    transform: translateX(-50%);
    white-space: normal;
  }
  
  /* Text box toolbar (for Quill editor) */
  .text-box-container:hover .quill-editor .ql-toolbar {
    display: block;
    z-index: 2000;
    pointer-events: auto;
  }
  .text-box-edit-mode {
    display: flex;
    pointer-events: auto;
  }
  
  /* Default button styles (example) */
  .button.dark-mode {
    background-color: #333;
    color: #fff;
    border-color: #444;
  }
  
  /* Menu item hover */
  /* .menu-item:hover {
    background-color: #f4f4f4;
    color: #000;
  } */
  
  /* Ant Design overrides
  .ant-menu-item:hover {
    background-color: var(--ant-menu-hover-bg) !important;
    color: var(--ant-menu-hover-text) !important;
  }
  
  .dark .ant-menu-item:hover {
    background-color: #001529 !important;
    color: rgb(151, 110, 110) !important;
  }
  .ant-layout-sider .ant-layout-sider-trigger {
    background-color: var(--ant-sider-trigger-bg) !important;
    color: var(--ant-sider-trigger-text) !important;
  }
  .dark .ant-layout-sider .ant-layout-sider-trigger {
    background-color: #001529 !important;
    color: var(--ant-sider-trigger-text) !important;
  } */
  
  /* Dashboard container */
  .dashboard-container {
    padding: 20px;
    transition: background-color 0.3s ease;
    background-color: var(--dashboard-bg);
    color: var(--color-text);
  }
  .dashboard-title {
    text-align: center;
    color: inherit;
  }
  .mode-switch {
    text-align: right;
    padding-bottom: 20px;
  }
  
  /* Statistics and chart cards */
  .statistics-card,
  .chart-card {
    border-radius: 8px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  .light-mode .statistics-card,
  .light-mode .chart-card {
    background: var(--card-bg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .dark-mode .statistics-card,
  .dark-mode .chart-card {
    background: var(--card-bg);
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  }
  .statistics-card .ant-statistic-title,
  .statistics-card .ant-statistic-content,
  .chart-card .ant-card-head-title {
    color: inherit;
  }
  .chart-image {
    width: 100%;
    height: auto;
  }
  
  /* Responsive grid adjustments */
  @media (max-width: 768px) {
    .dashboard-container {
      padding: 10px;
    }
    .mode-switch {
      padding-bottom: 10px;
    }
  }
  
  /* Image container */
  .image-container {
    text-align: center;
    margin: 20px 0;
  }
  .responsive-image {
    max-width: 50%;
    height: auto;
  }
  
  /* ContentSection.css */
  .content-section {
    background: var(--content-section-bg);
    color: var(--color-text);
    border-radius: 20px;
    padding: 30px;
    margin: 30px auto;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative;
    max-width: 90%;
  }
  .content-section:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  }
  /* Heading styling in content section */
  .content-heading h1 {
    font-size: 1.2rem;
    font-family: 'Unbounded', cursive;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 15px;
  }
  /* Body text styling in content section */
  .content-body {
    font-family: 'Unbounded', cursive;
    line-height: 1.5;
    text-align: justify;
  }
  /* Tooltip and description styling in content section */
  .content-description {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    transition: opacity 0.3s, visibility 0.3s;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    border-radius: 0 0 15px 15px;
    box-sizing: border-box;
  }
  .content-section:hover .content-description {
    visibility: visible;
    opacity: 1;
  }
  .content-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: none;
    border-radius: 20px;
    pointer-events: none;
    opacity: 0;
  }
  
  /* App styles */
  .App {
    text-align: center;
  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .App-link {
    color: #61dafb;
  }
  @keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  /* Tutorial (Dark Mode commented out, Light Mode active) */
  /* Tutorial Light Mode */
  .TutorialContainer {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 40px;
    max-width: 95%;
    margin-top: 20px;
    margin: auto;
    line-height: 1.6;
    color: var(--tutorial-text);
    background-color: var(--tutorial-bg);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .TutorialHeader {
    text-align: left;
    padding: 0px;
    background: linear-gradient(45deg, var(--tutorial-bg), var(--tutorial-bg));
    color: var(--color-secondary);
    border-radius: 15px;
    margin-bottom: 20px;
  }
  .TutorialHeader h1 {
    margin: 0;
    font-size: 2rem;
  }
  .TutorialHeader p {
    margin: 10px 0 0;
    font-size: 1rem;
    color: var(--color-secondary);
  }
  section {
    margin-bottom: 40px;
  }
  section p, section ul {
    margin: 10px 0;
    color: var(--color-text);
  }
  section ul {
    padding-left: 20px;
  }
  section ul li {
    margin-bottom: 10px;
  }
  a {
    color: var(--color-secondary);
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  .syntaxhighlighter {
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  .TutorialIntro,
  .TutorialInstallation,
  .TutorialConnection,
  .TutorialQuerying,
  .TutorialAdvanced,
  .TutorialFAQ,
  .TutorialCommunity {
    padding: 10px;
    background: var(--tutorial-bg);
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0.2, 0.2, 0.2, 0.2);
  }
  .TutorialIntro h2,
  .TutorialInstallation h2,
  .TutorialConnection h2,
  .TutorialQuerying h2,
  .TutorialAdvanced h2,
  .TutorialFAQ h2,
  .TutorialCommunity h2 {
    color: var(--color-secondary);
  }
  .TutorialIntro p,
  .TutorialInstallation p,
  .TutorialConnection p,
  .TutorialQuerying p,
  .TutorialAdvanced p,
  .TutorialFAQ p,
  .TutorialCommunity p {
    margin: 10px 0;
  }
  .CodeSnippet {
    position: relative;
  }
  .CopyIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.2rem;
    color: var(--color-secondary);
    cursor: pointer;
  }
  .CopyIcon:hover {
    color: #005f99;
  }
  

  
  /* Ant Tabs */
  .ant-tabs-tabpane {
    display: auto;
    justify-content: left;
    align-items: left;
  }
  
  /* Layout */
  .layout {
    min-height: 100vh;
  }
  .overview-layout {
    min-height: 20vh;
  }
  .header {
    background: #001529;
    color: #fff;
    text-align: center;
    padding: 0 50px;
  }
  .header .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.2);
  }
  .menu {
    line-height: 64px;
  }
  .content {
    padding: 0 50px;
    margin-top: 64px;
  }
  .introduction,
  .what-is-polkadot,
  .shared-security,
  .governance,
  .ecosystem,
  .developer-community,
  .appendices-resources,
  .about,
  .disclaimer {
    background-color: var(--card-bg);
    padding: 24px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
  p {
    line-height: 1.6;
  }
  a {
    color: #1890ff;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  ul {
    padding-left: 20px;
  }
  li {
    margin-bottom: 10px;
  }
  .img-container {
    text-align: center;
  }
  .img-container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  .tabs {
    margin-top: 24px;
  }
  .card-style {
    background-color: var(--card-bg);
    padding: 24px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
  .card-title {
    font-size: 1.5em;
    margin-bottom: 16px;
  }
  .card-body {
    padding: 0;
  }
  .container {
    padding: 16px;
  }
  .row {
    margin: 0 -8px;
  }
  .col-md-12 {
    padding: 0 8px;
  }
  .chart-container {
    width: 100%;
    height: 400px;
    margin-bottom: 24px;
  }
  .mt-5 {
    margin-top: 3rem !important;
  }
  .m-1 {
    margin: 1rem !important;
  }
  
  /* Basic table styles */
  .c80 {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px;
    text-align: justify;
  }
  .c80 thead tr {
    background-color: var(--table-header-bg);
    color: var(--table-header-text);
  }
  .c80 tbody tr:nth-child(even) {
    background-color: var(--table-row-even-bg);
  }
  .c80 tbody tr:nth-child(odd) {
    background-color: var(--table-row-odd-bg);
  }
  .c80 th, .c80 td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    vertical-align: middle;
  }
  .c80 tbody tr:hover {
    background-color: var(--table-row-hover-bg);
  }
  
  /* Link styles */
  .c5 {
    color: #3498db;
    text-decoration: none;
  }
  .c5:hover {
    text-decoration: underline;
  }
  
  /* Paragraph styles */
  .c16 {
    margin: 0;
  }
  .c3 {
    color: var(--color-text);
    text-align: justify;
  }
  
  /* Image styles */
  .parachain-logo {
    display: block;
    margin: 10px auto;
    max-width: 100%;
    height: auto;
  }
  
  /* Custom Tab Bar */
  .custom-tab-bar {
    background-color: var(--card-bg);
    font-size: 14px;
  }
  .custom-tab-bar .ant-tabs-tab {
    color: var(--tab-button-color);
  }
  .custom-tab-bar .ant-tabs-tab-active {
    background-color: var(--card-bg);
    color: var(--tab-button-color) !important;
  }
  


/* Grid container */

  /* Website grid container */
  .website-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2000px));
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 2000px;
    margin: 0 auto;
  }

/* Card styling */
.polkadot-card {
  width: 100%;
  max-width: 100%;
  padding: 20px;
  border: 2px solid var(--border-color);
  border-radius: 15px;
  background-color: var(--card-bg);
  box-shadow: var(--box-shadow);
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Card header */
.card-header {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header h2 {
  margin: 0;
  margin-right: 10px;
}

/* Tooltip styling */
.tooltip {
  position: absolute;
  transform: translate(-50%, -30%);
  z-index: 2;
  font-size: 14px;
  color: white;
  background-color: var(--tooltip-bg);
  padding: 20px;
  border-radius: 10px;
  max-width: 50%;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0,0,0,0.4);
}

/* Chart container */
.chart-container {
  height: 350px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-collapse .ant-collapse-content {
  background-color: inherit !important;
  padding: 0 !important;
  border: none !important;
}

